/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
export default {
  name: "Empty",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: "暂无记录"
    }
  },
  methods: {
    emptyClick: function emptyClick() {
      this.$emit('emptyClick');
    }
  }
};