/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import WeekCalendar from '@/components/WeekCalendar/WeekCalendar.vue';
import Confirm from 'vux/src/components/confirm';
import Grid from 'vux/src/components/grid/grid';
import GridItem from 'vux/src/components/grid/grid-item';
import TransferDom from 'vux/src/directives/transfer-dom';
import XButton from 'vux/src/components/x-button';
import Empty from '@/components/Empty/Empty';
export default {
  name: 'MeetingRoomDetail',
  directives: {
    TransferDom: TransferDom
  },
  components: {
    WeekCalendar: WeekCalendar,
    Confirm: Confirm,
    XButton: XButton,
    Grid: Grid,
    GridItem: GridItem,
    Empty: Empty
  },
  data: function data() {
    return {
      bh: '',
      //会议室编号
      timestamp: 0,
      //当前时间的时间戳
      eventList: ['2018-11-18', '2018-11-22', '2018-11-26', '2018-11-28', '2018-11-30', '2018-12-01', '2018-12-06'],
      //活动/事件数组
      roomId: this.$utils.Store.getLocalItem('roomId') ? this.$utils.Store.getLocalItem('roomId') : '',
      //会议室编号
      roomName: this.$utils.Store.getLocalItem('roomName') ? this.$utils.Store.getLocalItem('roomName') : '',
      //会议室名称
      deviceList: [{
        deviceName: '投影仪',
        deviceBh: 'tyy'
      }, {
        deviceName: '笔记本',
        deviceBh: 'bjb'
      }, {
        deviceName: '音响',
        deviceBh: 'yx'
      }, {
        deviceName: '麦克风',
        deviceBh: 'mkf'
      }, {
        deviceName: '打印机',
        deviceBh: 'dyj'
      }, {
        deviceName: '摄像头',
        deviceBh: 'sxt'
      }, {
        deviceName: 'WiFi',
        deviceBh: 'wifi'
      }],
      //会议室设备数组
      timeData: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'],
      //时间数组
      meetingList: [//会议室会议数组，meetingState--0：已结束；1：进行中；2：已预约  isMy--0:是我预约的;1:不是我预约的
      ],
      //会议室会议数组
      showToast: false,
      //是否显示弹窗
      emptyShow: true,
      //是否显示空数据
      emptyTip: '本会议室当前无会议预约',
      canOrder: false,
      //是否可以预约
      curTime: '',
      //当前时间
      ratio: 1,
      //px与rem换算比率
      allLen: 0,
      allHeight: 0,
      show: false,
      roomDetailhuoqugaodu: 0
    };
  },
  computed: {},
  methods: {
    myClick: function myClick() {
      this.$router.push('/MyMeetingOrder');
    },

    /**
     * @function获取当前日期
     *      wzx
     *   2019.01.22
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == 600) {
          _this.currDate = data.sysDate.substring(0, 10);
          _this.timestamp = _this.$utils.DateUtils.getTimeStamp(data.sysDate) * 1000;
          _this.canOrder = true;

          _this.getRoomMeetingList(_this.currDate);

          _this.getListHeight();
        } else {
          _this.$utils.Tools.toastShow(msg);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /**
     * @function获取当前日期下的与自己有关的会议日期列表
     *      wzx
     *   2019.01.23
     **/
    getMyMeetingDateList: function getMyMeetingDateList() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Meeting.getMyMeetingDateList({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.eventList = data;
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },

    /**
     * @function获取当前日期下的会议列表
     *      wzx
     *   2019.01.22
     **/
    getRoomMeetingList: function getRoomMeetingList(day) {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Meeting.selectMeetingDetail({
        cdate: day,
        roomBh: this.roomId,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);

          _this3.emptyShow = true;
        } else {
          if (data.meetList == null || data.meetList.length == 0) {
            _this3.meetingList = [];
            _this3.emptyShow = true;
          } else {
            _this3.meetingList = data.meetList;
            _this3.emptyShow = false;

            _this3.initTimeLine(data.meetList);

            var curIndex = _this3.meetingList.findIndex(_this3.fn);

            var stop = 0,
                curTop = 0;

            if (curIndex > 0) {
              stop = (_this3.meetingList[curIndex].curTop + 0.1) * _this3.ratio;
            } else {
              curTop = (_this3.$utils.DateUtils.getMinutesStamp(_this3.curTime.substring(11, 16)) - _this3.$utils.DateUtils.getMinutesStamp(_this3.timeData[0])) / _this3.allLen * _this3.allHeight;
              stop = (curTop + 0.1) * _this3.ratio;
            }

            setTimeout(function () {
              document.querySelector('.room-detail').scrollTop = stop;
            }, 20);
          }
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /**
     * @function获取当前会议下标
     *      wzx
     *   2019.03.13
     *   num代表当前项，numIndex代表当前项下标，nums代表该数组
     **/
    fn: function fn(num) {
      return num.kssj === this.curTime;
    },

    /**
     * @function获取当前页面比率
     *      wzx
     *   2019.03.13
     **/
    getRatio: function getRatio() {
      var sw = document.documentElement.clientWidth;

      if (sw > 640) {
        sw = 640;
      } else if (sw < 320) {
        sw = 320;
      }

      this.ratio = sw / 3.75;
    },
    monthClick: function monthClick(val) {
      // this.currDate = val;
      this.getRoomMeetingList(val);
    },
    dayClick: function dayClick(val) {
      // this.currDate = val;
      if (val < this.currDate) {
        this.canOrder = false;
      } else {
        this.canOrder = true;
      }

      this.getListHeight();
      this.getRoomMeetingList(val);
    },
    detailClick: function detailClick(value) {
      var state = encodeURI(value);
      this.$router.push({
        name: 'MeetingDetail',
        params: {
          state: state
        }
      });
    },
    repealClick: function repealClick(val) {
      this.showToast = true;
      this.bh = val;
    },
    orderClick: function orderClick() {
      this.$router.push({
        name: 'MakeOrder',
        query: {
          roomId: this.$utils.Store.getLocalItem('roomId'),
          roomName: this.$utils.Store.getLocalItem('roomName')
        }
      });
    },
    initTimeLine: function initTimeLine(data) {
      this.allLen = this.$utils.DateUtils.getMinutesStamp(this.timeData[this.timeData.length - 1]) - this.$utils.DateUtils.getMinutesStamp(this.timeData[0]); //获取时间轴总的时间差

      this.allHeight = (this.timeData.length - 1) * 0.6; //获取时间轴总的长度

      for (var i = 0; i < data.length; i++) {
        var curHeight = this.$utils.DateUtils.getMinutesStamp(data[i].jssj.substring(11, 16)) - this.$utils.DateUtils.getMinutesStamp(data[i].kssj.substring(11, 16)); //获取当前预约会议的时间差

        var curTop = this.$utils.DateUtils.getMinutesStamp(data[i].kssj.substring(11, 16)) - this.$utils.DateUtils.getMinutesStamp(this.timeData[0]); //获取当前预约会议的定位top的时间差

        var bili_height = curHeight / this.allLen * this.allHeight;
        var bili_top = curTop / this.allLen * this.allHeight;
        this.$set(this.meetingList[i], 'curHeight', bili_height);
        this.$set(this.meetingList[i], 'curTop', bili_top);
      }
    },
    onCancel: function onCancel() {},
    onConfirm: function onConfirm() {
      var _this4 = this;

      this.$api.Meeting.deleteMeetingOrder(this.bh, this.$utils.Store.getItem('userSerial'), '').then(function (response) {
        var code = response.code,
            msg = response.msg;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.getRoomMeetingList(_this4.currDate);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    onHide: function onHide() {},
    onShow: function onShow() {},
    getListHeight: function getListHeight() {
      var apH = this.$refs.apphuoqugaodu.clientHeight;
      var hbH = this.$refs.headerBoxhuoqugaodu.clientHeight;
      var ibH = this.$refs.infoBoxBoxhuoqugaodu.clientHeight;

      if (this.canOrder) {
        // let moH = $('.my-order').height();
        var moH = this.$refs.myOrderBoxhuoqugaodu.clientHeight;
        var rdH = apH - hbH - ibH - moH - 20; //$('.room-detail').height(rdH);

        this.roomDetailhuoqugaodu = rdH;
      } else {
        var rdHs = apH - hbH - ibH;
        this.roomDetailhuoqugaodu = rdHs; // $('.room-detail').height(rdHs);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getSysDate();
    this.getMyMeetingDateList();
    this.getRatio();
    this.roomId = this.$route.query.roomId;
    this.roomName = this.$route.query.roomName;
    this.curTime = this.$route.query.curTime;
    this.deviceList = JSON.parse(this.$route.query.devices);
    this.$utils.Store.removeItem('curActive');
    this.$utils.Store.removeItem('meetingType');
  },
  destroyed: function destroyed() {}
};